<template>
  <div>

    <b-card title="Create User">
      <validation-observer
        ref="createUserValidator"
      >
        <b-form @submit.prevent="onSubmit">
          <b-row>
            <b-col sm="6">
              <b-form-group
                label="Name"
                label-for="name"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    name="name"
                    placeholder="Enter name"
                  />
                  <span class="text-danger error-msg">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Username"
                label-for="username"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    v-model="form.username"
                    name="username"
                    placeholder="Enter username"
                  />
                  <span class="text-danger error-msg">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
            label="Password"
            label-for="password"
          >
            <validation-provider
              v-slot="{ errors }"
              name="password"
              rules="required|min:6"
            >
              <b-form-input
                id="password"
                v-model="form.password"
                name="password"
                placeholder="Enter password"
              />
              <span class="text-danger error-msg">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Confirm password"
            label-for="password_confirmation"
          >
            <validation-provider
              v-slot="{ errors }"
              name="confirm password"
              rules="required|confirmed:password|min:6"
            >
              <b-form-input
                id="password_confirmation"
                v-model="form.password_confirmation"
                name="password_confirmation"
                placeholder="Confrim password"
              />
              <span class="text-danger error-msg">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Role"
            label-for="role"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Role"
              rules="required"
            >
              <multiselect
                v-model="form.role"
                :options="roles"
                :multiple="false"
                open-direction="bottom"
                :show-no-results="false"
                :clear-on-select="false"
                :preserve-search="true"
                :preselect-first="false"
                :internal-search="true"
                :close-on-select="true"
                :allow-empty="false"
              />
              <span class="text-danger error-msg">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Postcode Groups"
            label-for="postcode_group"
          >
            <validation-provider
              v-slot="{ errors }"
              name="postcode group"
              rules="required"
            >
              <multiselect
                id="postcode_group"
                v-model="assingPostalGroupForm.postal_group_id"
                :options="postcodeGroups"
                :multiple="true"
                label="group_name"
                track-by="id"
                open-direction="bottom"
                :show-no-results="false"
                :clear-on-select="false"
                :preserve-search="true"
                :preselect-first="false"
                :internal-search="true"
                :close-on-select="true"
                :allow-empty="false"
              />
              <span class="text-danger error-msg">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
          <div class="d-flex justify-content-end">
            <b-button
              variant="primary"
              type="submit"
            >
              Create
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const appModule = createNamespacedHelpers('app')
const postcodeModule = createNamespacedHelpers('postcode')
const userModule = createNamespacedHelpers('user')

export default {
  data() {
    return {
      form: {
        name: '',
        username: '',
        password: '',
        password_confirmation: '',
        role: '',
      },
      assingPostalGroupForm: {
        user_id: null,
        postal_group_id: [],
      },
      roles: ['admin', 'csr', 'technician'],
      postcodeGroups: [],
    }
  },
  async mounted() {
    this.UPDATE_LOADER(true)
    await this.fetchAllPostcodeGroups()
    this.UPDATE_LOADER(false)
  },
  methods: {
    ...appModule.mapMutations(['UPDATE_LOADER']),
    ...postcodeModule.mapActions(['FETCH_ALL_POSTCODE_GROUPS']),
    ...userModule.mapActions(['ADD_USER', 'ASSIGN_POSTAL_GROUP_TO_USER']),

    async fetchAllPostcodeGroups() {
      try {
        this.UPDATE_LOADER(true)
        this.postcodeGroups = await this.FETCH_ALL_POSTCODE_GROUPS()
        this.UPDATE_LOADER(false)
      } catch (error) {
        this.isBusy = false
      }
    },

    onSubmit() {
      this.$refs.createUserValidator.validate().then(async success => {
        if (success) {
          const resp = await this.ADD_USER(this.form)
          if (resp) {
            this.assingPostalGroupForm.user_id = resp.data.data.id
            this.assingPostalGroupForm.postal_group_id = this.assingPostalGroupForm.postal_group_id.map(({ id }) => id)
            await this.ASSIGN_POSTAL_GROUP_TO_USER(this.assingPostalGroupForm)
            this.resetForm()
            this.$nextTick(() => {
              this.$refs.createUserValidator.reset()
            })
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'User Added!',
                icon: 'PlusIcon',
                variant: 'success',
                text: 'User has been added successfully!',
              },
            })
          }
        }
      })
    },
    resetForm() {
      this.form.name = ''
      this.form.username = ''
      this.form.password_confirmation = ''
      this.form.password = ''
      this.form.role = ''
      this.assingPostalGroupForm.user_id = null
      this.assingPostalGroupForm.postal_group_id = []
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
