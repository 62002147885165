var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"title":"Create User"}},[_c('validation-observer',{ref:"createUserValidator"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","placeholder":"Enter name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Username","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","name":"username","placeholder":"Enter username"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","name":"password","placeholder":"Enter password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Confirm password","label-for":"password_confirmation"}},[_c('validation-provider',{attrs:{"name":"confirm password","rules":"required|confirmed:password|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password_confirmation","name":"password_confirmation","placeholder":"Confrim password"},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Role","label-for":"role"}},[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.roles,"multiple":false,"open-direction":"bottom","show-no-results":false,"clear-on-select":false,"preserve-search":true,"preselect-first":false,"internal-search":true,"close-on-select":true,"allow-empty":false},model:{value:(_vm.form.role),callback:function ($$v) {_vm.$set(_vm.form, "role", $$v)},expression:"form.role"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Postcode Groups","label-for":"postcode_group"}},[_c('validation-provider',{attrs:{"name":"postcode group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"id":"postcode_group","options":_vm.postcodeGroups,"multiple":true,"label":"group_name","track-by":"id","open-direction":"bottom","show-no-results":false,"clear-on-select":false,"preserve-search":true,"preselect-first":false,"internal-search":true,"close-on-select":true,"allow-empty":false},model:{value:(_vm.assingPostalGroupForm.postal_group_id),callback:function ($$v) {_vm.$set(_vm.assingPostalGroupForm, "postal_group_id", $$v)},expression:"assingPostalGroupForm.postal_group_id"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Create ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }